/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAnonymous } from 'auth/selectors';
import request from 'core/util/superagent';
import { CartContext } from 'cart/constants';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ReadOnlyShippingInfoForm } from 'checkout/components/ShippingInfoForm';
import ReadOnlyPaymentConfirmation from './ReadOnlyPaymentConfirmation';
import CartPricingSummary from 'cart/components/CartPricingSummary';
import CartItemSummary from 'cart/components/CartItemSummary';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import './Confirmation.scss';
import { handleRegister } from 'auth/actions';
import RegisterForm from '../../../auth/components/LoginRegister/RegisterForm';
import { trackPurchase } from '../../../analytics/events';

class Confirmation extends Component {
  state = {
    order: {},
    redirectToReferrer: false,
  };

  componentDidMount() {
    const { authenticationToken, customerToken, match } = this.props;
    const { orderNumber } = match.params;
    request
      .get('/api/orders')
      .set('Content-Type', 'application/json')
      .set('Authorization', authenticationToken || null)
      .set('X-Customer-Token', customerToken || null)
      .query({ orderNumber })
      .end((err, response) => {
        if (err) this.props.history.push('/');

        if (response.body && response.body.length) {
          trackPurchase(response.body[0]);
          this.setState({
            order: response.body[0],
          });
        }
      });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { anonymous, match } = this.props;
    const { orderNumber } = match.params;
    const { order } = this.state;
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    const shippableFulfillmentGroup = find(
      order.fulfillmentGroups || [],
      (fg) =>
        !['DIGITAL', 'GIFT_CARD', 'PHYSICAL_PICKUP'].includes(
          fg.fulfillmentType
        )
    );
    const payment = find(
      order.payment || [],
      (p) =>
        ['CREDIT_CARD', 'COD'].includes(p.type) &&
        p.isActive &&
        !isEmpty(p.transactions)
    );

    var formattedDate = new Date(order.submitDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    formattedDate = formattedDate.slice(0, 6) + ', ' + formattedDate.slice(6);
    return (
      <div styleName="Confirmation">
        <Helmet titleTemplate="%s | Reddot Food Store">
          <title>Confirmation</title>
          <meta name="description" content="Confirmation" />
          <link rel="canonical" href="/confirmation" />
        </Helmet>

        <div className="container">
          <div className="row" styleName="ConfirmationContainer">
            <div className="col-lg-12">
              <div className="col-lg-12">
                <h3 styleName="ConirmationOrderHeader">
                  Thank you for Your Order!
                </h3>
              </div>
              <div className="col-lg-12" styleName="OrderTotalHeaderText">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div
                    styleName="OrderSummaryHeaderText"
                    className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                  >
                    Order Number:
                  </div>
                  <div
                    styleName="OrderSummaryHeaderValue"
                    className="col-lg-8 col-md-6 col-sm-12 col-xs-12"
                  >
                    {`${orderNumber}`}
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div
                    styleName="OrderSummaryHeaderText"
                    className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                  >
                    Order Date:
                  </div>
                  <div
                    styleName="OrderSummaryHeaderValue"
                    className="col-lg-8 col-md-6 col-sm-12 col-xs-12"
                  >
                    {`${formattedDate}`}
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div
                    styleName="OrderSummaryHeaderText"
                    className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                  >
                    Estimated Arrival
                  </div>
                  <div
                    styleName="OrderSummaryHeaderValue"
                    className="col-lg-8 col-md-6 col-sm-12 col-xs-12"
                  >
                    In 5-7 Business days
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6" styleName="ShippingToDiv">
                {!isEmpty(shippableFulfillmentGroup) && (
                  <div>
                    <div styleName="Confirmation__stageTitle">
                      <h4>Shipping To</h4>
                    </div>
                    <div className="card-content" styleName="CardContent">
                      <ReadOnlyShippingInfoForm
                        address={shippableFulfillmentGroup.address}
                      />
                    </div>
                  </div>
                )}
                {/*!isEmpty(payment) && (
                                    <div className='card'>
                                        <div styleName='Confirmation__stageTitle'>
                                            <FormattedMessage
                                                defaultMessage='Payment'
                                                id='checkout.stages.payment'
                                                tagName='h4' />
                                        </div>
                                        <div className='card-content'>
                                            <ReadOnlyPaymentConfirmation {...payment} />
                                        </div>
                                    </div>
                                )*/}
              </div>
              {/*<div
                className="col-sm-12  col-lg-6"
                styleName="BillingAddressDiv"
              >
                {!isEmpty(shippableFulfillmentGroup) && (
                  <div>
                    <div styleName="Confirmation__stageTitle">
                      <h4>Billing Address</h4>
                    </div>
                    <div className="card-content" styleName="CardContent">
                      <ReadOnlyShippingInfoForm
                        address={shippableFulfillmentGroup.archivedAddress}
                      />
                    </div>
                  </div>
                )}
              </div>*/}
              {!isEmpty(order) && (
                <span>
                  <div className="col-md-6 col-lg-8">
                    <CartItemSummary
                      context={CartContext.Confirmation}
                      orderItem={order.orderItems}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div styleName="CardPricing__title">
                      <h4>ORDER NUMBER {orderNumber}</h4>
                    </div>
                    <CartPricingSummary
                      anonymous={anonymous}
                      context={CartContext.Confirmation}
                      {...order}
                    />
                  </div>
                </span>
              )}
              {/*<div className='col-lg-12'>
                                {order.customer && (
                                    <FormattedMessage
                                        id='confirmation.confirmEmail.toBeSent'
                                        values={{
                                            '0': order.emailAddress || order.customer.emailAddress
                                        }}>
                                        {formattedMessage => (
                                            <p className='text-center' dangerouslySetInnerHTML={{ __html: formattedMessage }} />
                                        )}
                                    </FormattedMessage>
                                )}
                                        </div>*/}
              {/* Demo Disclaimer 
                            <div className="alert alert-danger payment-alert">
                                <div className="container-fluid">
                                    <div className="alert-icon">
                                        <i className="material-icons">error_outline</i>
                                    </div>
                                    <b>Alert:</b> <FormattedMessage id='demoDisclaimer' />
                                </div>
                            </div>*/}
            </div>
          </div>
          {anonymous && (
            <div className="row" styleName="RegistrationContainer">
              <RegisterForm onSubmit={this._handleRegisterSubmit} />
            </div>
          )}
        </div>
      </div>
    );
  }

  _handleRegisterSubmit = (form) => {
    return this.props.handleRegister(form).then((action) => {
      if (action.payload.error) {
        throw new SubmissionError({
          _error:
            'Registering an account with the information provided failed. Please try again',
        });
      } else {
        window.location.reload();
      }
    });
  };
}

const mapStateToProps = (state) => {
  return {
    anonymous: isAnonymous(state),
    authenticationToken: state.auth.authenticationToken,
    customerToken:
      state.auth.anonymousCustomerToken || state.csr.csrCustomerToken,
  };
};

export default connect(mapStateToProps, { handleRegister })(Confirmation);
