/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import {
  addCustomerAddress,
  fetchCustomerAddresses,
  removeCustomerAddress,
  updateCustomerAddress,
} from 'account/actions';
import CheckoutField from 'checkout/components/CheckoutField';
import Button from 'material-kit/components/Button';
import states from 'layout/util/us-states.json';
import countries from 'layout/util/eu-countryCodes.json';
import euCountries from 'layout/util/euCountryCodes.js';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import ManageAdressesScss from './ManageAddresses.scss';
import GlobalScss from '../../../layout/style/common.scss';
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';

class ManageAddresses extends PureComponent {
  static defaultProps = {
    addresses: [],
    stateOptions: states,
    countries: countries,
    addEditAdressesForm: false,
  };

  state = {
    selectedAddressId: undefined,
    addEditAdressesForm: false,
  };

  _onRemoveAddress = (addressId) => {
    if (addressId == 0) {
      this.setState({
        addEditAdressesForm: false,
      });
      return null;
    } else {
      return this.props.removeCustomerAddress(addressId).then((action) => {
        this.setState({
          selectedAddressId: 0,
          addEditAdressesForm: false,
        });
      });
    }
  };

  _onCancelAddress = (addressId) => {
    this.setState({
      addEditAdressesForm: false,
    });
  };

  _onSelectAddress = (id) => {
    this.setState({
      selectedAddressId: id,
      addEditAdressesForm: true,
    });
  };

  _onSubmitForm = (form) => {
    return (
      !this.state.selectedAddressId
        ? this.props.addCustomerAddress(form)
        : this.props.updateCustomerAddress(form)
    ).then((action) => {
      if (action.payload.error) {
        throw new SubmissionError({ _error: 'Error submitting the address' });
      } else {
        this.addressForm.reset();
        this.props.fetchCustomerAddresses();
        this.setState({
          addEditAdressesForm: false,
        });
      }
    });
  };

  render() {
    const currentAddress = find(this.props.addresses, {
      id: this.state.selectedAddressId,
    });
    return (
      <div>
        <div styleName="GlobalScss.Page_header">
          <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 col-12">
            <h3 styleName="GlobalScss.Title">My Address Book</h3>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
            <Button
              disabled={
                this.state.selectedAddressId == 0 &&
                this.state.addEditAdressesForm == true
              }
              primary
              /*styleName='Add_address_button'*/
              onClick={(e) => this._onSelectAddress(0)}
            >
              Add Address
            </Button>
          </div>
        </div>
        <div styleName="GlobalScss.Text-below-header">
          <p>Displaying {this.props.addresses.length} saved address</p>
        </div>

        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          styleName={
            this.state.addEditAdressesForm
              ? 'ManageAdressesScss.Edit_add_addesses_body'
              : 'ManageAdressesScss.hidden'
          }
        >
          <ManageAddresses.Form
            initialValues={currentAddress}
            onSubmit={this._onSubmitForm}
            ref={(ref) => (this.addressForm = ref)}
            stateOptions={this.props.stateOptions}
            countryOptions={this.props.countries}
            onCancelAddress={this._onCancelAddress}
          />
        </div>

        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          styleName="ManageAdressesScss.ManageAddresses_Manage"
        >
          <ManageAddresses.Manage
            addresses={this.props.addresses}
            onRemoveAddress={this._onRemoveAddress}
            onCancelAddress={this._onCancelAddress}
            onSelectAddress={this._onSelectAddress}
            selectedAddressId={this.state.selectedAddressId}
          />
        </div>
      </div>
    );
  }
}

ManageAddresses.Manage = ({
  addresses,
  onRemoveAddress,
  onCancelAddress,
  onSelectAddress,
  selectedAddressId,
}) => (
  <div className="address-cards address-cards-primary">
    {addresses.map(({ id, addressName, address }) => (
      <div
        styleName="ManageAdressesScss.Profile_body"
        key={id}
        className={selectedAddressId === id ? 'active' : ''}
      >
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div styleName="ManageAdressesScss.AdressItem_title">Address</div>
          <div styleName="ManageAdressesScss.AdressItem_value">
            <strong>{addressName}</strong>
            {address.default && <small>&nbsp;&nbsp;(Default)</small>}
            <br />
            {address.firstName}&nbsp;{address.lastName}
            <br />
            {address.addressLine1}
            <br /> {address.addressLine2}
            {address.addressLine2 && <br />}
            {address.city}
            <br />
            {address.isoCountryAlpha2 != null && address.isoCountryAlpha2.name}
            <br />
            {address.postalCode}
            <br />
            {address.phonePrimary && address.phonePrimary.phoneNumber}
          </div>
        </div>
        <div
          className="col-lg-6 col-md-6 col-sm-12"
          styleName="ManageAdressesScss.AdressItem_buttons_div"
        >
          <button
            styleName="ManageAdressesScss.AdressItem_button"
            onClick={(e) => onSelectAddress(id)}
          >
            <label styleName="ManageAdressesScss.AdressItem_button_label">
              Edit
            </label>{' '}
            <img
              styleName="ManageAdressesScss.AdressItem_button_icon"
              src="/static/img/edit.svg"
            />
          </button>
          <button
            styleName="ManageAdressesScss.AdressItem_button"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveAddress(id);
            }}
          >
            <label styleName="ManageAdressesScss.AdressItem_button_label">
              Remove
            </label>{' '}
            <img
              styleName="ManageAdressesScss.AdressItem_button_icon"
              src="/static/img/trash.svg"
            />
          </button>
          <div></div>
        </div>
        <div
          className="col-sm-12"
          styleName={
            address.isDefault
              ? 'ManageAdressesScss.Profile_unfocused_text'
              : 'ManageAdressesScss.hidden'
          }
        >
          This is your default delivery address
        </div>
      </div>
    ))}
  </div>
);

const FormValidators = {
  required: (value) => (value ? undefined : 'Required'),
};

ManageAddresses.Form = reduxForm({
  enableReinitialize: true,
  form: 'ManageAddressesForm',
  // initialValues: {
  //     address: {
  //         isoCountryAlpha2: {
  //             alpha2: 'US'
  //         }
  //     }
  // }
})(
  ({
    initialValues,
    error,
    handleSubmit,
    stateOptions,
    countryOptions,
    submitSucceeded,
    onRemoveAddress,
    onCancelAddress,
    setState,
  }) => (
    <div>
      <Form onSubmit={handleSubmit}>
        {!error && submitSucceeded && (
          <span className="text-success">
            You successfully {isEmpty(initialValues) ? 'added' : 'modified'} the
            address!
          </span>
        )}
        <Field
          autoComplete="off"
          component={CheckoutField}
          inputGroup={false}
          label="Address Name"
          name="addressName"
          type="text"
          validate={FormValidators.required}
        />
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="First Name"
          name="address.firstName"
          type="text"
          validate={FormValidators.required}
        />
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="Last Name"
          name="address.lastName"
          type="text"
          validate={FormValidators.required}
        />
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="Address"
          name="address.addressLine1"
          type="text"
          validate={FormValidators.required}
        />
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="Address 2"
          name="address.addressLine2"
          type="text"
        />
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="City"
          name="address.city"
          type="text"
          validate={FormValidators.required}
        />

        <Field
          component={CheckoutField}
          inputGroup={false}
          label="Country"
          name="address.isoCountryAlpha2.alpha2"
          type="select"
          validate={FormValidators.required}
        >
          <option value=""></option>
          {countryOptions.map((option) => (
            <option key={option.abbreviation} value={option.abbreviation}>
              {option.name}
            </option>
          ))}
        </Field>
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="Postal Code"
          name="address.postalCode"
          type="text"
          validate={FormValidators.required}
        />
        <Field
          component={PhoneNumberField}
          inputGroup={false}
          label="Phone"
          placeholder="Enter your phone"
          name={'address.phonePrimary.phoneNumber'}
          validate={FormValidators.required}
        />
        <br></br>
        <Field
          component={CheckoutField}
          inputGroup={false}
          label="Default Shipping"
          name="address.isDefault"
          type="checkbox"
        />

        <div
          className="text-right"
          styleName="ManageAdressesScss.Form_buttons_div"
        >
          {error && <div className="text-danger">{error}</div>}
          <Button
            type="submit"
            primary
            styleName="ManageAdressesScss.Add_address_button ManageAdressesScss.Submit_address_button"
          >
            Save Address
          </Button>
          <Button
            type="button"
            primary
            onClick={(e) => {
              e.stopPropagation();
              onCancelAddress(initialValues.address.id);
            }}
            styleName="ManageAdressesScss.Add_address_button ManageAdressesScss.Cancel_Remove_address_button"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )
);

const mapStateToProps = (state) => {
  return {
    isFetching: state.customerAddresses.isFetching,
    addresses: state.customerAddresses.customerAddresses,
  };
};

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchCustomerAddresses);
  }
};

export default connect(mapStateToProps, {
  addCustomerAddress,
  fetchCustomerAddresses,
  removeCustomerAddress,
  updateCustomerAddress,
})(resolve(dispatchResolve)(ManageAddresses));
