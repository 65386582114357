import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { Link, withRouter } from 'react-router-dom';
import {
  fetchExistingCart,
  removeFromCart,
  toggleMiniCart,
} from 'cart/actions';
import { isAnonymous } from 'auth/selectors';
import { getCart, getItemCount } from 'cart/selectors';
import classNames from 'classnames';
import MiniCartItem from 'cart/components/MiniCartItem';
import Button from 'material-kit/components/Button';
import { format } from 'layout/util/moneyUtils';
import map from 'lodash/map';
import filter from 'lodash/filter';
import './MiniCart.scss';
import CartModal from '../CartModal/CartModal.js'; // Importujemo CartModal

class MiniCart extends PureComponent {
  static propTypes = {
    isOpened: PropTypes.bool,
  };

  state = {
    modalIsOpen: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.anonymous !== nextProps.anonymous) {
      nextProps.fetchExistingCart();
    }
  }

  _handleClick = (e) => {
    if (this.props.location.pathname === '/cart') {
      e.preventDefault();
      e.stopPropagation();
      this.props.toggleMiniCart(false);
      return false;
    }

    if (document) document.body.style.overflow = 'hidden';
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  _onClose = () => {
    if (document) document.body.style.overflow = 'auto';
    this.setState({ modalIsOpen: false });
  };

  _getDropdownStyle = () => {
    if (!this.openMiniCartLink) {
      return {};
    }

    const bodyRect = document.body.getBoundingClientRect();
    const targetRect = this.openMiniCartLink.getBoundingClientRect();
    return {
      top: targetRect.bottom - 5,
      right: bodyRect.right - targetRect.right,
    };
  };

  _renderItem = (orderItem) => (
    <MiniCartItem
      key={orderItem.id}
      {...orderItem}
      location={this.props.location}
      removeFromCart={this.props.removeFromCart}
    />
  );

  render() {
    const { cart, itemCount } = this.props;

    return (
      <div className={'dropdown' + (this.state.modalIsOpen ? ' open' : '')}>
        <a
          ref={(ref) => (this.openMiniCartLink = ref)}
          className="dropdown-toggle"
          onClick={this._handleClick}
          styleName={`MiniCart__toggle${this.state.modalIsOpen ? '--active' : ''}`}
        >
          <img src={'/static/img/bag.svg'} styleName="MenuBagIcon" />
          {itemCount ? <span>{itemCount}</span> : ''}
        </a>
        <CartModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this._onClose}
          cart={cart}
          props={this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    anonymous: isAnonymous(state),
    cart: getCart(state),
    itemCount: getItemCount(state),
    isOpened: state.miniCart.isOpened,
  };
};

const dispatchResolve = (resolver, props) => {
  resolver.resolve(props.fetchExistingCart);
};

export default withRouter(
  connect(mapStateToProps, {
    fetchExistingCart,
    removeFromCart,
    toggleMiniCart,
  })(resolve(dispatchResolve)(MiniCart))
);
