import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import MiniCartModalItem from 'cart/components/MiniCartModalItem';
import { Link } from 'react-router-dom';
import { format } from 'layout/util/moneyUtils';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Button from 'material-kit/components/Button';
import Price from 'material-kit/components/Price';
import CartModalStyle from './CartModal.module.scss';
import { trackBeginCheckout } from '../../../analytics/events';
import classNames from 'classnames';

const CartModal = ({ isOpen, onRequestClose, cart, props }) => {
  const modalRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const unlisten = props.history.listen(() => {
      if (isOpen) {
        closeModal();
      }
    });

    return () => {
      unlisten();
    };
  }, [isOpen, onRequestClose, props.history]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onRequestClose]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (modalRef.current) {
          modalRef.current.style.transform = 'translateX(0px)';
        }
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.style.transform = 'translateX(400px)';
    }
    setIsAnimating(true);

    const timer = setTimeout(() => {
      onRequestClose();
      setIsAnimating(false);
    }, 200);

    return () => clearTimeout(timer);
  };

  return (
    <Modal
      isOpen={isOpen || isAnimating}
      onRequestClose={closeModal}
      contentLabel="Cart Modal"
      className={CartModalStyle.cart_modal_content}
      overlayClassName={CartModalStyle.cart_modal_overlay}
    >
      <div ref={modalRef} className={CartModalStyle.CartModalContent}>
        <div className={CartModalStyle.Cart__itemSummary__title}>
          <h4>My Cart</h4>
          <a className="pull-right" onClick={closeModal}>
            <img
              className={CartModalStyle.CloseIcon}
              src="/static/img/close.svg"
            />
          </a>
        </div>
        {map(
          filter(cart.orderItems, (o) => !o.parentOrderItemId),
          (orderItem) => (
            <MiniCartModalItem
              key={orderItem.id}
              {...orderItem}
              location={props.location}
              removeFromCart={props.removeFromCart}
            />
          )
        )}
        {!!cart.orderItems && (
          <li className={classNames('col-xs-12', CartModalStyle.PaddingZero)}>
            <div className={CartModalStyle.SubtotalParent}>
              <div
                className={classNames('col-xs-6', CartModalStyle.SubtotalLabel)}
              >
                Subtotal
              </div>
              <div
                className={classNames('col-xs-6', CartModalStyle.PaddingZero)}
              >
                <div
                  className={classNames('pull-right', CartModalStyle.BoldPrice)}
                >
                  <Price
                    price={format(cart.subTotal.amount, cart.subTotal.currency)}
                  />
                </div>
              </div>
            </div>
          </li>
        )}
        {!!cart.orderItems && (
          <li
            className={classNames(
              'col-xs-12',
              CartModalStyle.CheckoutButtonParent
            )}
          >
            <Button
              onClick={(e) => trackBeginCheckout(cart)}
              primary
              component={Link}
              componentProps={{
                to: {
                  pathname: '/cart',
                },
              }}
            >
              Go to Checkout
            </Button>
          </li>
        )}
        {!cart.orderItems && (
          <li className="col-xs-12">
            <div className={CartModalStyle.EmptyCartDiv}>
              Your cart is empty
            </div>
          </li>
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
