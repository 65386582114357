/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React from 'react';
import { connect } from 'react-redux';
import { isCsrMode } from 'csr/selectors';
import { isPreviewing } from 'preview/selectors';
import { withRouter } from 'react-router-dom';
import ScrollToTop from 'layout/components/ScrollToTop';
import Header from 'layout/components/Header';
import Footer from 'layout/components/Footer';
import MessagePortal from 'layout/components/MessagePortal';
import Navbar from 'material-kit/components/Navbar';
import FullPageLayoutStyle from './FullPageLayout.module.scss';
import GlobalScss from '../../../layout/style/common.scss';
import classNames from 'classnames';
import TopOptionalInfoWidget from 'content/components/widgets/TopOptionalInfoWidget';

export const FullPageLayout = ({ children, csrMode, previewing, location }) => {
  const isLoginPage = ['/login', '/register'].includes(location.pathname);
  const sc = {
    values: {
      htmlContent: [
        'FREE SHIPPING on orders above €60',
        'Made in India, Made to Order | Delivered Straight to Your Doorstep',
      ],
    },
  };
  return (
    <div
      className={
        !isLoginPage
          ? [FullPageLayoutStyle.FullPageLayout]
          : classNames(
              FullPageLayoutStyle.FullPageLayout,
              FullPageLayoutStyle.FullPageLayout_backgroundColor
            )
      }
    >
      <TopOptionalInfoWidget sc={sc} />
      {!isLoginPage && (
        <Navbar
          className={
            !previewing && !csrMode
              ? classNames(
                  FullPageLayoutStyle.FullPageLayoutNavbar,
                  FullPageLayoutStyle.FullPageLayout__navbar
                )
              : [FullPageLayoutStyle['FullPageLayout__navbar--preview']]
          }
          top
        >
          <div className={GlobalScss.GridWrapper}>
            <Header />
          </div>
        </Navbar>
      )}
      <section
        className={classNames(
          FullPageLayoutStyle.FullPageLayout__content,
          'main'
        )}
        role="main"
        id="AppContent"
      >
        {children}
      </section>

      <MessagePortal />
      <ScrollToTop />
      {!isLoginPage && <Footer />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    csrMode: isCsrMode(state),
    previewing: isPreviewing(state),
  };
};

export default withRouter(connect(mapStateToProps)(FullPageLayout));
