import React, { useEffect, useState, useRef, PureComponent } from 'react';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import MenuModalStyle from './MenuModal.module.scss';
import { fetchMenu } from 'menu/actions';
import { getMenu } from 'menu/selectors';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

const MenuModal = ({
  menuItems,
  isOpen,
  onRequestClose,
  onLogout,
  isAnonymous,
}) => {
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const modalRef = useRef(null);

  const handleIconClick = (index) => {
    setExpandedIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onRequestClose();
      }
    };

    if (document !== 'undefined') {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      if (document !== 'undefined') {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Modal"
      className={MenuModalStyle.menu_modal}
      overlayClassName={MenuModalStyle.menu_modal_overlay}
    >
      <div ref={modalRef} styleName="MenuModalStyle.MenuModal_Content">
        <div
          className={classNames('col-xs-12', MenuModalStyle.MenuModal_Title)}
        >
          <a className="pull-right" onClick={onRequestClose}>
            <img
              className={MenuModalStyle.CloseIcon}
              src="/static/img/close.svg"
            />
          </a>
        </div>
        <div className={classNames('col-xs-12', MenuModalStyle.MenuModal_List)}>
          {menuItems.map((menuItem, index) => {
            if (isEmpty(menuItem.submenu)) {
              return (
                <li key={menuItem.label} className={MenuModalStyle.MenuItem}>
                  <NavLink
                    onClick={onRequestClose}
                    to={getMenuItemUrl(menuItem.url)}
                  >
                    {menuItem.label}
                  </NavLink>
                </li>
              );
            }

            return (
              <li key={menuItem.label} className="dropdown">
                <div
                  className={classNames(
                    MenuModalStyle.CategoryWithSubMenuParentDiv,
                    MenuModalStyle.MenuItem
                  )}
                >
                  <div>
                    <NavLink
                      onClick={onRequestClose}
                      to={getMenuItemUrl(menuItem.url)}
                    >
                      {menuItem.label}
                    </NavLink>
                  </div>
                  <div className={MenuModalStyle.CategorySubMenuContent}>
                    <i
                      className={classNames(
                        'material-icons',
                        expandedIndexes.includes(index)
                          ? MenuModalStyle.SubCategoryArrowDown
                          : MenuModalStyle.SubCategoryArrowUp
                      )}
                      onClick={() => handleIconClick(index)}
                    >
                      keyboard_arrow_down
                    </i>
                  </div>
                </div>
                <ul
                  className={classNames(
                    MenuModalStyle.SubCategoryList,
                    expandedIndexes.includes(index)
                      ? MenuModalStyle.SubCategoryContentExpanded
                      : MenuModalStyle.SubCategoryContentCollapsed
                  )}
                >
                  {menuItem.submenu.map((submenuItem) => (
                    <li
                      key={submenuItem.label}
                      styleName="MenuModalStyle.SubMenuItem"
                    >
                      <NavLink
                        onClick={onRequestClose}
                        to={getMenuItemUrl(submenuItem.url)}
                      >
                        {submenuItem.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
          {isAnonymous && (
            <li key="signIn" className={MenuModalStyle.MenuItem}>
              <NavLink onClick={onRequestClose} to={'/login'}>
                Sign In
              </NavLink>
              <img
                src={'/static/img/user.svg'}
                className={MenuModalStyle.SignInImg}
              />
            </li>
          )}
        </div>
      </div>
    </Modal>
  );
};

class MenuContainer extends PureComponent {
  componentWillReceiveProps(nextProps) {
    if (!this.props.isFetching && this.props.menuName !== nextProps.menuName) {
      nextProps.fetchMenu(nextProps.menuName);
    }
  }

  render() {
    if (isEmpty(this.props.menuItems)) {
      return false;
    }

    return <MenuModal {...this.props} />;
  }
}

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchMenu, props.menuName);
  }
};

const getMenuItemUrl = (url) => (url !== '/' ? `${url}` : '/');

export default connect(getMenu, { fetchMenu })(
  resolve(dispatchResolve)(MenuContainer)
);
