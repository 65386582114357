/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import {
  addCustomerPayment,
  fetchCustomerAddresses,
  fetchCustomerPayments,
  makePaymentDefault,
  removeCustomerPayment,
  updateCustomerPayment,
} from 'account/actions';
import { getCustomerAddresses, getCustomerPayments } from 'account/selectors';
import SamplePaymentService from 'checkout/service/SamplePaymentService';
import { FormattedMessage } from 'react-intl';
import AddressPicker from 'account/components/AddressPicker';
import CheckoutField from 'checkout/components/CheckoutField';
import CreditCardTypes from './CreditCardTypes';
import CreditCardFields from './CreditCardFields';
import SavedPaymentCardField from './SavedPaymentCardField';
import Button from 'material-kit/components/Button';
import isEmpty from 'lodash/isEmpty';
import ManagePaymentsScss from './ManagePayments.scss';
import GlobalScss from '../../../layout/style/common.scss';

class ManagePayments extends PureComponent {
  state = {
    success: undefined,
  };

  _onSubmit = (form) => {
    const { creditCard, customerPayment } = form;
    SamplePaymentService.tokenizeCard(creditCard).then((nonce) => {
      if (nonce) {
        if (customerPayment.billingAddress.paymentId) {
          if (customerPayment.isDefault) {
            this.props
              .makePaymentDefault(customerPayment.billingAddress.paymentId)
              .then(() => {
                this.form.reset();
                this.setState({
                  success:
                    'You have successfully selected your default payment!',
                });
              });
          }
        } else {
          this.props
            .addCustomerPayment({
              customerPayment,
              nonce,
            })
            .then(() => {
              this.form.reset();
              this.setState({
                success: 'You have succesfully added a new payment method!',
              });
            });
        }
      }
    });
  };

  _onMakeDefault = (id) => {
    this.props.makePaymentDefault(id).then(() => {
      this.form.reset();
      this.setState({
        success: 'You have successfully selected your default payment!',
      });
    });
  };

  _onRemove = (id) => {
    this.props.removeCustomerPayment(id).then(() => {
      this.form.reset();
      this.setState({
        success: 'You have successfully deleted a payment method!',
      });
    });
  };

  _clearMessaging = () => {
    this.setState({
      success: undefined,
    });
  };

  render() {
    const { customerAddresses, customerPayments } = this.props;

    const billingAddresses = this.props.customerPayments.map((payment) => ({
      ...payment.billingAddress,
      paymentId: payment.id,
    }));
    return (
      <div onClick={(e) => this._clearMessaging()}>
        <ManagePayments.Form
          onSubmit={this._onSubmit}
          ref={(ref) => (this.form = ref)}
        >
          <div styleName="GlobalScss.Page_header">
            <div styleName="GlobalScss.Title">Manage Payment Methods</div>
          </div>
          <div styleName="GlobalScss.Text-below-header">
            Change your default billing address to speed up checkout process
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12"
            styleName="GlobalScss.Content_Panel"
          >
            {/*<hr />

                        {!isEmpty(customerPayments) ? (
                            <div className='row'>
                                <Field component={SavedPaymentCardField} customerPayments={customerPayments} name='customerPayment' onMakeDefault={this._onMakeDefault} onRemove={this._onRemove} />
                            </div>
                        ) : (
                            <FormattedMessage
                                defaultMessage='You have no saved payments.'
                                id='account.payments.noSavedPayments'
                                tagName='p' />
                        )}

                        <hr />*/}
            <div className="col-xs-12">
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-xs-6"
                styleName="ManagePaymentsScss.OrderPayment"
              >
                <a href="#" styleName="ManagePaymentsScss.PaymentImageParent">
                  <img
                    src={'/static/img/RevolutButtonIcon.svg'}
                    styleName="ManagePaymentsScss.PaymentImage"
                  />
                </a>
              </div>
              <div
                className="col-lg-9 col-md-6 col-sm-6 col-xs-6"
                styleName="ManagePaymentsScss.PaymentDescription"
              >
                Revolut Pay
              </div>
            </div>
            <div className="col-xs-12">
              {this.state.success && (
                <span className="text-success">{this.state.success}</span>
              )}
            </div>
            <div className="col-xs-12">
              <FormattedMessage
                defaultMessage="Billing Information"
                id="cart.billingInformation"
                tagName="h4"
              />

              <AddressPicker
                addresses={billingAddresses}
                excludedFields={['isDefault']}
                name="customerPayment.billingAddress"
              />
            </div>
            <div
              className="col-xs-12 col-sm-12"
              styleName="ManagePaymentsScss.DefaultCheckboxDiv"
            >
              <Field
                component={CheckoutField}
                inputGroup={false}
                label="Make Default"
                name="customerPayment.isDefault"
                type="checkbox"
              />
            </div>
            <div className="col-xs-12 col-sm-6 pull-right">
              <Button
                primary
                type="submit"
                className="pull-right"
                styleName="ManagePaymentsScss.Save_button"
              >
                <FormattedMessage
                  defaultMessage="Save"
                  id="account.payments.save"
                />
              </Button>
            </div>
          </div>
        </ManagePayments.Form>
      </div>
    );
  }
}

ManagePayments.Form = reduxForm({
  enableReinitialize: true,
  form: 'ManagePaymentsForm',
  initialValues: {
    creditCard: {
      creditCardNumber: '4111111111111111',
      creditCardName: 'Hotsauce Connoisseur',
      creditCardExpDate: '01/99',
      creditCardCvv: '123',
    },
    customerPayment: {
      billingAddress: {
        isoCountryAlpha2: {
          alpha2: 'US',
        },
      },
    },
  },
})(({ children, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>{children}</Form>
));

const mapStateToProps = (state) => {
  return {
    customerAddresses: getCustomerAddresses(state),
    customerPayments: getCustomerPayments(state),
  };
};

const dispatchResolve = (resolver, props) => {
  resolver.resolve(props.fetchCustomerPayments);
  resolver.resolve(props.fetchCustomerAddresses);
};

export default connect(mapStateToProps, {
  addCustomerPayment,
  fetchCustomerAddresses,
  fetchCustomerPayments,
  makePaymentDefault,
  removeCustomerPayment,
  updateCustomerPayment,
})(resolve(dispatchResolve)(ManagePayments));
