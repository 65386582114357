import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from 'material-kit/components/Button';
import SearchInputStyle from './SearchInput.module.scss';

const SearchInput = ({
  focused,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onSubmit,
  value,
}) => {
  const [placeholder, setPlaceholder] = useState('What are you looking for ?');

  // Function to update the placeholder based on screen width
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setPlaceholder('Search');
    } else {
      setPlaceholder('What are you looking for ?');
    }
  };

  // useEffect to listen for window resize events
  useEffect(() => {
    // Set the initial placeholder
    handleResize();

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={classNames({
        'is-empty': !value,
        'is-focused': focused,
        [SearchInputStyle.SearchInputParent]: true,
      })}
    >
      <div className="input-group">
        <input
          className={SearchInputStyle.SearchInput}
          placeholder={placeholder}
          autoComplete="off"
          type="text"
          name="search"
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        <span
          className={classNames(
            'input-group-btn',
            SearchInputStyle.SearchIconDiv
          )}
        >
          <Button
            className={SearchInputStyle.SearchIconBtn}
            primary
            simple
            round
            type="button"
            onClick={onSubmit}
          >
            <img
              src={'/static/img/search.svg'}
              className={SearchInputStyle.SearchIcon}
            />
          </Button>
        </span>
      </div>
    </div>
  );
};

export default SearchInput;
