/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'material-kit/components/Button';
import Price from 'material-kit/components/Price';
import MiniCartModalItemStyle from './MiniCartModalItem.module.scss';
import classNames from 'classnames';

class MiniCartModalItem extends PureComponent {
  static defaultProps = {
    excludeActions: false,
  };

  render() {
    const {
      excludeActions,
      hasValidationError,
      id,
      name,
      primaryMedia,
      productUrl,
      orderItemAttributes,
      productOptionsValue,
      quantity,
      salePrice,
    } = this.props;
    return (
      <li
        className={classNames(
          'col-xs-12',
          MiniCartModalItemStyle.MiniCartModalItem
        )}
      >
        <div className="row">
          <div
            className={classNames(
              'col-xs-12',
              MiniCartModalItemStyle.MiniCartModalItem_Title
            )}
          >
            <div
              className={classNames(
                'col-xs-6',
                MiniCartModalItemStyle.MiniCartModalItem__image
              )}
            >
              <img
                src={
                  primaryMedia ? primaryMedia.url : '/static/img/noPhoto.png'
                }
                alt={primaryMedia ? primaryMedia.altText : 'No photo available'}
              />
            </div>
            <div className="col-xs-6">
              {!excludeActions && (
                <div
                  className={classNames(
                    'pull-right',
                    MiniCartModalItemStyle.MiniCartItem_buttons_div
                  )}
                >
                  <button
                    className={MiniCartModalItemStyle.MiniCartItem_button}
                    onClick={(e) => {
                      this.props.removeFromCart(id);
                    }}
                  >
                    <label
                      className={
                        MiniCartModalItemStyle.MiniCartItem_button_label
                      }
                    >
                      Delete
                    </label>{' '}
                    <img
                      className={
                        MiniCartModalItemStyle.MiniCartItem_button_icon
                      }
                      src="/static/img/trash.svg"
                    />
                  </button>
                  <Link
                    to={{
                      pathname: `${productUrl}`,
                      state: {
                        orderItemId: id,
                        from: this.props.location,
                      },
                    }}
                  >
                    <button
                      className={MiniCartModalItemStyle.MiniCartItem_button}
                    >
                      <label
                        className={
                          MiniCartModalItemStyle.MiniCartItem_button_label
                        }
                      >
                        Edit
                      </label>{' '}
                      <img
                        className={
                          MiniCartModalItemStyle.MiniCartItem_button_icon
                        }
                        src="/static/img/edit.svg"
                      />
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'col-xs-12',
              MiniCartModalItemStyle.PaddingZero
            )}
          >
            <div
              className={classNames(
                'col-xs-6',
                MiniCartModalItemStyle.MiniCartModalItem__image
              )}
            >
              <div className={MiniCartModalItemStyle.name}>{name}</div>
            </div>
            <div className="col-xs-6">
              <div
                className={classNames(
                  'pull-right',
                  MiniCartModalItemStyle.BoldPrice
                )}
              >
                <Price price={salePrice} />
              </div>
            </div>
          </div>
          {orderItemAttributes &&
            orderItemAttributes.map((orderItemAtribute) => (
              <div
                className={classNames(
                  'col-xs-12',
                  MiniCartModalItemStyle.PaddingZero
                )}
              >
                <div
                  className={classNames(
                    'col-xs-6',
                    MiniCartModalItemStyle.MiniCartModalItem__properties
                  )}
                >
                  <div
                    className={
                      MiniCartModalItemStyle.MiniCartModalItem__properties_name
                    }
                  >
                    {orderItemAtribute.name}
                  </div>
                </div>
                <div className="col-xs-6">
                  <div
                    className={classNames(
                      'pull-right',
                      MiniCartModalItemStyle.MiniCartModalItem__properties_value
                    )}
                  >
                    {orderItemAtribute.value}
                  </div>
                </div>
              </div>
            ))}
          <div
            className={classNames(
              'col-xs-12',
              MiniCartModalItemStyle.PaddingZero
            )}
          >
            <div
              className={classNames(
                'col-xs-6',
                MiniCartModalItemStyle.MiniCartModalItem__properties
              )}
            >
              <div
                className={
                  MiniCartModalItemStyle.MiniCartModalItem__properties_name
                }
              >
                QUANTITY
              </div>
            </div>
            <div className="col-xs-6">
              <div
                className={classNames(
                  'pull-right',
                  MiniCartModalItemStyle.MiniCartModalItem__properties_value
                )}
              >
                X {quantity}
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default MiniCartModalItem;
