/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FacetList from 'catalog/search/components/FacetList';
import ResultList from 'catalog/search/components/ResultList';
import ResultListFooter from 'catalog/search/components/ResultListFooter';
import ResultWrapper from 'catalog/search/components/ResultWrapper';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import SearchResultsStyle from './SearchResults.module.scss';
import classNames from 'classnames';
import { format } from 'layout/util/moneyUtils';

class SearchResults extends Component {
  static propTypes = {
    location: PropTypes.object,
    results: PropTypes.array,
    metadata: PropTypes.shape({
      page: PropTypes.number,
      pageSize: PropTypes.number,
      totalResults: PropTypes.number,
      totalPages: PropTypes.number,
      searchFacets: PropTypes.array,
    }),
    headerTitle: PropTypes.string,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    results: [],
    metadata: {},
    isLoading: false,
  };

  state = {
    facetsVisible: false, // State to track if facets are visible
  };

  componentDidMount() {
    window.addEventListener('click', this.updateResultListHeight);
    this.updateResultListHeight();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.metadata.searchFacets !== this.props.metadata.searchFacets ||
      prevProps.results !== this.props.results
    ) {
      this.updateResultListHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.updateResultListHeight);
  }

  updateResultListHeight = () => {
    /*const facetsDiv = document.getElementById('facetsDiv');
    if (facetsDiv) {
      const height = facetsDiv.clientHeight;
      if (document.getElementById('resultListDiv'))
        document.getElementById('resultListDiv').style.height = `${height}px`;
    }*/
  };

  handleFacetRemove = (fieldName, key) => {
    const { location, history } = this.props;
    const search = queryString.parse(location.search);

    search.page = undefined;

    if (!Array.isArray(search[fieldName])) {
      search[fieldName] = undefined;
    } else {
      search[fieldName] = search[fieldName].filter((value) => {
        return value !== key;
      });
    }

    history.push({
      ...location,
      search: queryString.stringify(search),
    });
  };

  toggleFacets = () => {
    this.setState((prevState) => ({
      facetsVisible: !prevState.facetsVisible,
    }));
  };

  render() {
    const { headerTitle, isLoading, location, match, metadata, results } =
      this.props;
    const searchFacets = this.props.metadata.searchFacets || [];

    return (
      <ResultWrapper
        isLoading={isLoading}
        location={location}
        headerTitle={headerTitle}
        match={match}
        metadata={metadata}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Button to toggle facets visibility */}
              <button
                onClick={this.toggleFacets}
                className={SearchResultsStyle.facetButton}
              >
                <img
                  className={SearchResultsStyle.facetButtonIcon}
                  src="/static/img/filters.svg"
                ></img>
                {/*this.state.facetsVisible ? 'Hide Facets' : 'Show Facets'*/}
              </button>
            </div>

            {
              <div
                id="facetsDiv"
                className={classNames(
                  'col-md-4',
                  'facetList',
                  SearchResultsStyle.FacetListParentDiv,
                  {
                    [SearchResultsStyle.facet_open]: this.state.facetsVisible,
                    [SearchResultsStyle.facet_closed]:
                      !this.state.facetsVisible,
                  }
                )}
              >
                {!isLoading && (
                  <FacetList searchFacet={metadata.searchFacets} />
                )}
              </div>
            }
            <div className="col-md-8">
              {!isLoading && (
                <div className={SearchResultsStyle.activeFacets}>
                  {searchFacets.map((facet) => {
                    const { fieldName, values } = facet;

                    return values.map((value) => {
                      if (value.active) {
                        return (
                          <div
                            key={`${fieldName}-${value.valueKey}`}
                            className={SearchResultsStyle.facetSquare}
                          >
                            <span className={SearchResultsStyle.facetLabel}>
                              {value.value ? (
                                <small>{`${value.value} (${value.quantity})`}</small>
                              ) : (
                                <small>
                                  {/*TODO: swap out for global currency later*/}
                                  {format(value.minValue, 'EUR')}{' '}
                                  {value.maxValue
                                    ? ' - ' + format(value.maxValue, 'EUR')
                                    : ' or more'}{' '}
                                  ({value.quantity})
                                </small>
                              )}
                            </span>

                            <span>
                              <a
                                className={SearchResultsStyle.iconX}
                                onClick={() =>
                                  this.handleFacetRemove(
                                    fieldName,
                                    value.valueKey
                                  )
                                }
                              >
                                <img src="/static/img/minorX.svg" />
                              </a>
                            </span>
                          </div>
                        );
                      }

                      return null;
                    });
                  })}
                </div>
              )}
              {!isLoading && <ResultList results={results} />}
              {!isLoading && (
                <ResultListFooter location={location} metadata={metadata} />
              )}
            </div>
          </div>
        </div>
      </ResultWrapper>
    );
  }
}

const NoResultsFound = ({ classes, headerTitle }) => (
  <div className="container">
    <p>No Results found for {headerTitle}</p>
  </div>
);

export default withRouter(SearchResults);
