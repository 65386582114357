import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import MenuSubcategoryStyle from './MenuSubCategoryModal.module.scss';
import classNames from 'classnames';

const MenuSubCategoryModal = ({
  isOpen,
  onRequestClose,
  onLogout,
  onMouseEnter,
  onMouseLeave,
  subMenuMap,
}) => {
  const modalRef = useRef(null);
  const [hoveredItem, setHoveredItem] = useState(
    subMenuMap.length > 0 ? subMenuMap[0] : null
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onRequestClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onRequestClose]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (modalRef.current) {
          modalRef.current.style.opacity = '1';
        }
      }, 1);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && subMenuMap.length > 0) {
      setHoveredItem(subMenuMap[0]);
    }
  }, [isOpen, subMenuMap]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Modal"
      parentSelector={() => document.getElementById('AppContent')}
      className={MenuSubcategoryStyle.menu_subcategory_modal_content}
      overlayClassName={MenuSubcategoryStyle.menu_subcategory_modal_overlay}
    >
      <div
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        ref={modalRef}
        className={classNames(
          'col-xs-12',
          MenuSubcategoryStyle.MenuSubcategory_Content
        )}
      >
        <div
          className={classNames(
            'col-xs-12',
            MenuSubcategoryStyle.MenuSubMainContent
          )}
        >
          <div
            className={classNames(
              'col-xs-6',
              MenuSubcategoryStyle.MenuSubRightContent
            )}
          >
            {hoveredItem && (
              <img
                src={hoveredItem.imageUrl}
                className={MenuSubcategoryStyle.MenuSubImage}
                alt={`${hoveredItem.label} image`}
              />
            )}
            {!hoveredItem && (
              <img
                src={subMenuMap.length > 0 ? subMenuMap[0].imageUrl : ''}
                className={MenuSubcategoryStyle.MenuSubImage}
              />
            )}
          </div>
          <div
            className={classNames(
              'col-xs-6',
              MenuSubcategoryStyle.MenuSubLeftContent
            )}
          >
            <div
              className={classNames(
                'col-xs-12',
                MenuSubcategoryStyle.MenuSubFlexColumn
              )}
            >
              {subMenuMap.map((submenuItem) => (
                <div
                  key={submenuItem.url}
                  className={MenuSubcategoryStyle.MenuSubMenuItem}
                >
                  <li>
                    <NavLink
                      onMouseEnter={() => setHoveredItem(submenuItem)}
                      to={getMenuItemUrl(submenuItem.url)}
                      className={
                        hoveredItem === submenuItem
                          ? MenuSubcategoryStyle.hovered
                          : ''
                      }
                      onClick={onRequestClose}
                    >
                      {submenuItem.label}
                    </NavLink>
                  </li>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const getMenuItemUrl = (url) => (url !== '/' ? `${url}` : '/');

export default MenuSubCategoryModal;
