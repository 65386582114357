import React, { PureComponent, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { NavLink } from 'react-router-dom';
import Navbar from 'material-kit/components/Navbar';
import { fetchMenu } from 'menu/actions';
import { getMenu } from 'menu/selectors';
import isEmpty from 'lodash/isEmpty';
import MenuStyle from './Menu.module.scss';
import classNames from 'classnames';
import MenuSubCategoryModal from './MenuSubCategoryModal';

const Menu = ({ menuItems }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [hoveringMenuItem, setHoveringMenuItem] = useState(false);
  const [hoveringModal, setHoveringModal] = useState(false);

  const menuItemRef = useRef(null);
  const modalRef = useRef(null);

  const handleMouseEnterMenuItem = () => {
    setHoveringMenuItem(true);
    setModalVisible(true);
    setHoveringModal(false);
  };

  const handleMouseLeaveMenuItem = () => {
    setHoveringMenuItem(false);
    setTimeout(() => {
      if (!hoveringModal) {
        setModalVisible(false);
      }
    }, 100);
  };

  const handleMouseEnterModal = () => {
    setHoveringModal(true);
    setHoveringMenuItem(false);
  };

  const handleMouseLeaveModal = () => {
    setHoveringModal(false);
    setTimeout(() => {
      if (!hoveringMenuItem) {
        setModalVisible(false);
      }
    }, 100);
  };

  return (
    <div className={classNames('main-menu-wrapper', MenuStyle.MenuWrapper)}>
      <Navbar.Nav className={classNames('Menu', MenuStyle.Menu)}>
        {menuItems.map((menuItem) => {
          if (isEmpty(menuItem.submenu)) {
            return (
              <li key={menuItem.label}>
                <NavLink
                  className={MenuStyle.Menu__link}
                  to={getMenuItemUrl(menuItem.url)}
                >
                  {menuItem.label}
                </NavLink>
              </li>
            );
          }

          if (!menuItem.categoryId) {
            return (
              <li key={menuItem.label} className="dropdown">
                <NavLink
                  className={MenuStyle.Menu__link}
                  to={getMenuItemUrl(menuItem.url)}
                >
                  {menuItem.label}
                </NavLink>
                <ul className="dropdown-menu dropdown-with-icons">
                  {menuItem.submenu.map((submenuItem) => (
                    <li key={submenuItem.label}>
                      <NavLink
                        className={MenuStyle.Menu__link}
                        to={getMenuItemUrl(submenuItem.url)}
                      >
                        {submenuItem.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            );
          }

          return (
            <li
              key={menuItem.label}
              className="dropdown"
              onMouseEnter={handleMouseEnterMenuItem}
              onMouseLeave={handleMouseLeaveMenuItem}
              ref={menuItemRef}
            >
              <NavLink
                className={MenuStyle.Menu__link}
                to={getMenuItemUrl(menuItem.url)}
              >
                {menuItem.label}
                <i
                  className={classNames(
                    'material-icons',
                    isModalVisible
                      ? MenuStyle.SubCategoryArrowDown
                      : MenuStyle.SubCategoryArrowUp
                  )}
                  onClick={() => handleIconClick(index)}
                >
                  keyboard_arrow_down
                </i>
              </NavLink>
              <MenuSubCategoryModal
                isOpen={isModalVisible}
                onRequestClose={handleMouseLeaveModal}
                onMouseEnter={handleMouseEnterModal}
                onMouseLeave={handleMouseLeaveModal}
                ref={modalRef}
                subMenuMap={menuItem.submenu}
              />
            </li>
          );
        })}
      </Navbar.Nav>
    </div>
  );
};

const NavMegaMenu = ({ menuItem }) => {
  const numSections = menuItem.submenu.length;
  return (
    <li className={classNames('dropdown', MenuStyle.NavMegaDropdown)}>
      <NavLink
        className={MenuStyle.Menu__link}
        to={getMenuItemUrl(menuItem.url)}
      >
        {menuItem.label}
      </NavLink>
      <ul className={classNames('dropdown-menu row', MenuStyle.NavMegaMenu)}>
        {menuItem.submenu.map((submenu) => {
          if (submenu.categoryId) {
            return (
              <li key={submenu.label} className={'col-sm-' + 12 / numSections}>
                <ul>
                  <li className={MenuStyle.Menu__title}>
                    <NavLink
                      className={MenuStyle.Menu__link}
                      to={getMenuItemUrl(submenu.url)}
                    >
                      {submenu.label}
                    </NavLink>
                  </li>
                  {submenu.submenu.map((submenuItem) => (
                    <li key={submenuItem.label}>
                      <NavLink
                        className={MenuStyle.Menu__link}
                        to={getMenuItemUrl(submenuItem.url)}
                      >
                        {submenuItem.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            );
          }

          return (
            <li key={submenu.label} className={'col-sm-' + 12 / numSections}>
              <ul>
                <li className="menu-title">{submenu.label}</li>
                {submenu.submenu.map((subSubMenu) => (
                  <li key={subSubMenu.label}>
                    <NavLink
                      className={MenuStyle.Menu__link}
                      to={getMenuItemUrl(subSubMenu.url)}
                    >
                      {subSubMenu.label}
                    </NavLink>
                    <ul>
                      {subSubMenu.submenu.map((subSubMenuItem) => (
                        <li key={subSubMenuItem.label}>
                          <NavLink
                            className={MenuStyle.Menu__link}
                            to={getMenuItemUrl(subSubMenuItem.url)}
                          >
                            {subSubMenuItem.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

const getMenuItemUrl = (url) => (url !== '/' ? `${url}` : '/');

class MenuContainer extends PureComponent {
  componentWillReceiveProps(nextProps) {
    if (!this.props.isFetching && this.props.menuName !== nextProps.menuName) {
      nextProps.fetchMenu(nextProps.menuName);
    }
  }

  render() {
    if (isEmpty(this.props.menuItems)) {
      return false;
    }

    return <Menu {...this.props} />;
  }
}

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchMenu, props.menuName);
  }
};

export default connect(getMenu, { fetchMenu })(
  resolve(dispatchResolve)(MenuContainer)
);
