/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FooterStyle from './Footer.module.scss';
import classNames from 'classnames';
import GlobalScss from '../../../layout/style/common.scss';
import CookieBot from 'react-cookiebot';

const domainGroupId = '7ff7e091-84fb-4457-b0fb-2694937f6ad2';

const Footer = () => {
  // const [showCookieDialog, setShowCookieDialog] = useState(false);
  //  const [hasCookieBot, setHasCookieBot] = useState(undefined);
  /*
  <div style={{ color: 'red', marginVertical: 10 }}>
          {hasCookieBot && `Has CookieBot: ${JSON.stringify(hasCookieBot)}`}
        </div>
      <button  onClick={() => setHasCookieBot(!!document.querySelector('#CookieBot'))} > Cookieeeeeee </button>

  */

  const handleCookiePolicyClick = () => {
    setShowCookieDialog(true);
  };

  return (
    <footer className={FooterStyle.Footer}>
      <CookieBot domainGroupId={domainGroupId} />
      <div
        className={classNames(
          GlobalScss.GridWrapper,
          FooterStyle.ContentWrapper
        )}
      >
        <div className={classNames(FooterStyle.FirstMainDiv, 'col-sm-12')}>
          <div className={FooterStyle.Logo}>
            <Link to="/">
              <img
                className={FooterStyle.FooterLogoImg}
                src={'/static/img/Logo_Rdfood_Final.svg'}
              />
            </Link>
          </div>
          <div className={FooterStyle.Links}>
            <div className={FooterStyle.FirstRowLinks}>
              <nav>
                <ul className={FooterStyle.NavItemParent}>
                  <li className={FooterStyle.NotClickable}>
                    <Link to="#">
                      <FormattedMessage
                        defaultMessage="ABOUT US"
                        id="footer.aboutUs"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <FormattedMessage
                        defaultMessage="Home"
                        id="footer.home"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/shop">
                      <FormattedMessage
                        defaultMessage="Shop All"
                        id="footer.shopAll"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/bulk-orders">
                      <FormattedMessage
                        defaultMessage="Bulk Orders"
                        id="footer.bulkOrders"
                      />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={FooterStyle.SecondRowLinks}>
              <nav>
                <ul className={FooterStyle.NavItemParent}>
                  <li className={FooterStyle.NotClickable}>
                    <Link to="#">
                      <FormattedMessage
                        defaultMessage="CONTACT & HELP"
                        id="footer.a"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <FormattedMessage defaultMessage="FAQs" id="footer.b" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus">
                      <FormattedMessage
                        defaultMessage="Contact Us"
                        id="footer.c"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipping-refund">
                      <FormattedMessage
                        defaultMessage="Shipping & Refund Policy"
                        id="footer.cc"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms">
                      <FormattedMessage
                        defaultMessage="Terms & Conditions"
                        id="footer.terms"
                      />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={FooterStyle.SecondRowLinks}>
              <nav>
                <ul className={FooterStyle.NavItemParent}>
                  <li className={FooterStyle.NotClickable}>
                    <Link to="#">
                      <FormattedMessage
                        defaultMessage="YOUR ACCOUNT"
                        id="footer.aa"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/login">
                      <FormattedMessage defaultMessage="Login" id="footer.ce" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/register">
                      <FormattedMessage
                        defaultMessage="Register"
                        id="footer.cc"
                      />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className={FooterStyle.SocialMediaParent}>
            <nav>
              <ul className={FooterStyle.NavSocialMediaParent}>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/reddot_food/"
                  >
                    <img
                      src={'/static/img/instagram.svg'}
                      className={FooterStyle.SocialButtonImg}
                    />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/reddotfood">
                    <img
                      src={'/static/img/facebook.svg'}
                      className={FooterStyle.SocialButtonImg}
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/100364780"
                  >
                    <img
                      src={'/static/img/linkedin.svg'}
                      className={FooterStyle.SocialButtonImg}
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={FooterStyle.SecondMainDiv}>
          <div>
            <div
              className={classNames(
                FooterStyle.Brand,
                FooterStyle.fotterBelowText
              )}
            >
              <p>&copy; 2024 Reddot Food. All rights reserved.</p>
            </div>
          </div>
          <div className={FooterStyle.PaymentPartnersParent}>
            <div
              className={classNames(
                FooterStyle.Brand,
                FooterStyle.fotterBelowText
              )}
            >
              <p className={FooterStyle.PaymentPartnersLabel}>
                Payment Partners
              </p>
              <p className={FooterStyle.PaymentPartnerCardImgParent}>
                <img
                  src={'/static/img/Revolut2.svg'}
                  className={FooterStyle.PaymentSchemeImg}
                />
              </p>
              <p className={FooterStyle.PaymentPartnerCardImgParent}>
                <img
                  src={'/static/img/mastercard2.svg'}
                  className={FooterStyle.PaymentSchemeImg}
                />
              </p>
              <p className={FooterStyle.PaymentPartnerCardImgParent}>
                <img
                  src={'/static/img/visa2.svg'}
                  className={FooterStyle.PaymentSchemeImg}
                />
              </p>
              <p className={FooterStyle.PaymentPartnerCardImgParent}>
                <img
                  src={'/static/img/googlePay.jpeg'}
                  className={FooterStyle.PaymentSchemeImg}
                />
              </p>
              <p className={FooterStyle.PaymentPartnerCardImgParent}>
                <img
                  src={'/static/img/applePay.jpeg'}
                  className={FooterStyle.PaymentSchemeImg}
                />
              </p>
            </div>
          </div>
          <div className={FooterStyle.LastItemInDiv}>
            <div>
              <p className={FooterStyle.PrivacyPolicy}>
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    defaultMessage="Privacy Policy"
                    id="footer.privacyPolicy"
                  />
                </a>{' '}
              </p>
            </div>
            <div>
              <p>
                <Link to="#">
                  <FormattedMessage
                    defaultMessage="Cookie Policy"
                    id="footer.cookiePolicy"
                  />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className={FooterStyle.ThirdMainDiv}>
          <div className={FooterStyle.FlexEnd}>
            <div
              className={classNames(
                FooterStyle.Brand,
                FooterStyle.fotterBelowText
              )}
            >
              <p className={FooterStyle.FooterBelowTextLabel}>Address</p>
              <p className={FooterStyle.FooterBelowTextValue}>
                P.O. Box Number 13667, Dublin 15, Co. Dublin, Ireland
              </p>
            </div>
          </div>
          <div className={FooterStyle.FlexEnd}>
            <div
              className={classNames(
                FooterStyle.Brand,
                FooterStyle.fotterBelowText
              )}
            >
              <p className={FooterStyle.FooterBelowTextLabel}>CRO Number</p>
              <p className={FooterStyle.FooterBelowTextValue}>740382</p>
            </div>
          </div>
          <div className={FooterStyle.FlexEnd}>
            <div
              className={classNames(
                FooterStyle.Brand,
                FooterStyle.fotterBelowText
              )}
            >
              <p className={FooterStyle.FooterBelowTextLabel}>VAT Number</p>
              <p className={FooterStyle.FooterBelowTextValue}>4152367KH</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
