/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { fetchCustomerAddresses, fetchCustomerPayments } from 'account/actions';
import {
  fetchFulfillmentEstimations,
  saveFulfillmentGroup,
  saveBillingAddressForNewPayment,
} from 'checkout/actions';
import {
  getInitialShippingValues,
  getFulfillmentEstimations,
} from 'checkout/selectors';
import { Field, Form, reduxForm, change } from 'redux-form';
import CheckoutField from 'checkout/components/CheckoutField';
import Button from 'material-kit/components/Button';
import states from 'layout/util/us-states.json';
import countries from 'layout/util/eu-countryCodes.json';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import ShippingInfoFormScss from './ShippingInfoForm.scss';
import GlobalScss from '../../../layout/style/common.scss';
import { isAnonymous, isAuthenticated } from 'auth/selectors';
import { saveEmailForCart } from 'cart/actions';
import Autocomplete from 'react-google-autocomplete';
import PhoneNumberField from '../../../account/components/PhoneNumberField/PhoneNumberField';
import { isValidPhoneNumber } from 'libphonenumber-js';

class ShippingInfoForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    countries: PropTypes.array,
  };

  static defaultProps = {
    countryOptions: countries,
  };

  state = {
    selectedAddressId: undefined,
    selectedBillingAddressId: undefined,
    currentAdrress: null,
    sameAsShipping: false,
    googleShippingAddressAutoComplete: null,
    googleBillinggAddressAutoComplete: null,
  };

  _onSelectAddress = (id) => {
    this.setState({
      ...this.state,
      selectedAddressId: id,
    });
  };

  _onSelectBillingAddress = (id) => {
    this.setState({
      ...this.state,
      //   selectedAddressId: currentAddress.id,
      selectedBillingAddressId: id,
    });
  };

  _onSubmit = (form) => {
    let billingAddress;

    if (true || this.state.sameAsShipping) {
      // Always same as shipping, if we want different billing addres, remove true from condition
      billingAddress = form.address;
    } else {
      billingAddress = form.billingAddress;
    }
    let submission = {
      ...this.props.initialValues,
      address: omit(form.address, ['id', 'phonePrimary.id']),
      billingAddress: billingAddress,
    };
    if (this.props.anonymous) {
      this.props.saveEmailForCart(form.emailAddress).then((action) => {
        if (action.payload.error) {
          throw new SubmissionError({
            _error: 'Unable to checkout with the given email address.',
          });
        } else {
          /*this.props
            .saveBillingAddressForNewPayment(billingAddress)
            .then((action) => {*/
          this.props.saveFulfillmentGroup(submission).then((action) => {
            if (!action.payload.error) {
              this.props.afterSubmit(this.props);
              return this.props;
            }
          });
          //});
        }
      });
    } else {
      /*this.props
        .saveBillingAddressForNewPayment(billingAddress)
        .then((action) => {*/
      this.props.saveFulfillmentGroup(submission).then((action) => {
        if (!action.payload.error) {
          this.props.afterSubmit(this.props);
          return this.props;
        }
      });
      //});
    }
  };

  _onChange = (e) => {
    const isChecked = e.target.checked;
    this.setState({ sameAsShipping: isChecked });
  };

  componentDidMount() {
    var shippingAddress1Field = document.getElementById('ShippingAddress1');
    //var billingAddress1Field = document.getElementById('BillingAddress1');

    //Boundaries of Europe Union + UK
    const southwest = { lat: 34.7266, lng: -9.7313 };
    const northeast = { lat: 70.1615, lng: 34.8687 };
    const defaultBounds = new google.maps.LatLngBounds(southwest, northeast);

    var options = {
      bounds: defaultBounds,
      strictBounds: true,
      types: ['address'],
      fields: ['address_components'],
    };

    this.state.googleShippingAddressAutoComplete =
      new google.maps.places.Autocomplete(shippingAddress1Field, options);
    this.state.googleShippingAddressAutoComplete.addListener(
      'place_changed',
      () => this.fillInAddress(true)
    );

    /*this.state.googleBillinggAddressAutoComplete =
      new google.maps.places.Autocomplete(billingAddress1Field, options);
    this.state.googleBillinggAddressAutoComplete.addListener(
      'place_changed',
      () => this.fillInAddress(false)
    );*/
  }

  fillInAddress = (isShipping) => {
    let place = null;
    if (isShipping)
      place = this.state.googleShippingAddressAutoComplete.getPlace();
    //else place = this.state.googleBillinggAddressAutoComplete.getPlace();

    let address1 = '';
    let postcode = '';
    let city = '';
    let country = '';

    if (isShipping) {
      this.props.change('ShippingInfoForm', 'address.city', null);
      this.props.change(
        'ShippingInfoForm',
        'address.isoCountryAlpha2.alpha2',
        null
      );
      this.props.change('ShippingInfoForm', 'address.postalCode', null);
    } /*else {
      this.props.change('ShippingInfoForm', 'billingAddress.city', null);
      this.props.change(
        'ShippingInfoForm',
        'billingAddress.isoCountryAlpha2.alpha2',
        null
      );
      this.props.change('ShippingInfoForm', 'billingAddress.postalCode', null);
    }*/

    for (const component of place.address_components) {
      for (const componentType of component.types) {
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality':
            city = component.long_name;
            break;
          case 'postal_town':
            if (!city || city == '') city = component.long_name;
            break;
          case 'country':
            if (!country || country == '') country = component.short_name;
            break;
        }
      }
    }

    if (isShipping) {
      this.props.change('ShippingInfoForm', 'address.city', city);
      this.props.change('ShippingInfoForm', 'address.postalCode', postcode);
      const foundCountry = this.props.countryOptions.find(
        (el) => el.abbreviation == country
      );
      if (foundCountry)
        this.props.change(
          'ShippingInfoForm',
          'address.isoCountryAlpha2.alpha2',
          country
        );
    } /*else {
      this.props.change('ShippingInfoForm', 'billingAddress.city', city);
      this.props.change(
        'ShippingInfoForm',
        'billingAddress.postalCode',
        postcode
      );
      const foundCountry = this.props.countryOptions.find(
        (el) => el.abbreviation == country
      );
      if (foundCountry)
        this.props.change(
          'ShippingInfoForm',
          'billingAddress.isoCountryAlpha2.alpha2',
          country
        );
    }*/
  };

  render() {
    var currentAddress = this.props.initialValues;
    for (var i = 0; i < this.props.customerAddresses.length; i++) {
      if (
        this.props.customerAddresses[i].address.id ==
        this.state.selectedAddressId
      ) {
        currentAddress = {
          ...currentAddress,
          address: this.props.customerAddresses[i].address,
        };
      }
    }
    /*const billingAddresses = this.props.customerPayments.map(
      (payment) => payment.billingAddress
    );
    for (var i = 0; i < billingAddresses.length; i++) {
      if (billingAddresses[i].id == this.state.selectedBillingAddressId) {
        currentAddress = {
          ...currentAddress,
          billingAddress: billingAddresses[i],
        };
      }
    }*/
    return (
      <div>
        <ShippingInfoForm.Form
          initialValues={currentAddress}
          customerAddresses={this.props.customerAddresses}
          //billingAddresses={billingAddresses}
          countryOptions={this.props.countryOptions}
          onSelectAddress={this._onSelectAddress}
          onSelectBillingAddress={this._onSelectBillingAddress}
          fulfillmentEstimations={this.props.fulfillmentEstimations}
          ref={(ref) => (this.form = ref)}
          onSubmit={this._onSubmit}
          handleChange={this._onChange}
          sameAsShipping={this.state.sameAsShipping}
          anonymous={this.props.anonymous}
        />
      </div>
    );
  }
}

const FormValidators = {
  required: (value) => (value ? undefined : 'Required'),
  validPhoneNumber: (value) =>
    isValidPhoneNumber(value) ? undefined : 'Invalid phone number',
};

ShippingInfoForm.Form = reduxForm({
  enableReinitialize: true,
  form: 'ShippingInfoForm',
})(
  ({
    initialValues,
    customerAddresses,
    //billingAddresses,
    countryOptions,
    onSelectAddress,
    onSelectBillingAddress,
    error,
    fulfillmentEstimations,
    handleSubmit,
    handleChange,
    sameAsShipping,
    anonymous,
  }) => (
    <Form
      onSubmit={handleSubmit}
      styleName="ShippingInfoFormScss.ShippingInfoForm"
    >
      {anonymous && (
        <div>
          <div styleName="ShippingInfoFormScss.CheckoutStages__title">
            <h4 styleName="ShippingInfoFormScss.ShippingDetailsHeader">
              Checkout
            </h4>
          </div>
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-12"
              styleName="ShippingInfoFormScss.Email_field"
            >
              <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                <Field
                  inputGroup={false}
                  addon="email"
                  component={CheckoutField}
                  label="Email"
                  placeholder="Enter your email here"
                  name="emailAddress"
                  type="email"
                  validate={FormValidators.required}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div styleName="ShippingInfoFormScss.CheckoutStages__title">
        <h4 styleName="ShippingInfoFormScss.ShippingDetailsHeader">
          Shipping details
        </h4>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          {!anonymous && !isEmpty(customerAddresses) && (
            <div>
              <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                <Field
                  component={CheckoutField}
                  inputGroup={false}
                  label="Select a saved address"
                  placeholder="Select address"
                  name="address.id"
                  type="select"
                  onChange={(e) => onSelectAddress(e.target.value)}
                >
                  <option value="">Select address</option>
                  {customerAddresses.map((option) => (
                    <option key={option.address.id} value={option.address.id}>
                      {option.address.addressLine1}
                    </option>
                  ))}
                </Field>
              </div>
              {/*{isEmpty(customerAddresses) && (
                            <div className='col-xs-12 col-sm-5 col-md-5 col-lg-5' styleName='GlobalScss.Text-below-header ShippingInfoFormScss.Saved_address_text'>
                                There are no saved addresses in your address book
                            </div>
                        )}
                        {!isEmpty(customerAddresses) && (
                            <div className='col-xs-12 col-sm-5 col-md-5 col-lg-5' styleName='GlobalScss.Text-below-header ShippingInfoFormScss.Saved_address_text'>
                                There are saved addresses in your address book
                            </div>
                        )} */}
            </div>
          )}
          {/* <Field autoComplete='off' component={AccountField} inputGroup={false} label='Address Name' name='addressName' type='text' validate={FormValidators.required} /> */}
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="First Name*"
              placeholder="Enter your first name"
              name="address.firstName"
              type="text"
              validate={FormValidators.required}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="Last Name*"
              placeholder="Enter your last name"
              name="address.lastName"
              type="text"
              validate={FormValidators.required}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              id="ShippingAddress1"
              component={CheckoutField}
              inputGroup={false}
              label="Address line 1*"
              placeholder="Enter your address 1 here"
              name="address.addressLine1"
              type="text"
              validate={FormValidators.required}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="Address line 2"
              placeholder="Enter your address 2 here"
              name="address.addressLine2"
              type="text"
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              id="shippingCountry"
              component={CheckoutField}
              inputGroup={false}
              label="Country*"
              placeholder="Country"
              name={'address.isoCountryAlpha2.alpha2'}
              type="select"
              validate={FormValidators.required}
            >
              <option value="">Select your country</option>
              {countryOptions.map((option) => (
                <option key={option.abbreviation} value={option.abbreviation}>
                  {option.name}
                </option>
              ))}
            </Field>
          </div>
          <div styleName="ShippingInfoFormScss.City-state-postalCode-div">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <Field
                id="shippingCity"
                component={CheckoutField}
                inputGroup={false}
                label="City*"
                placeholder="City"
                name="address.city"
                type="text"
                validate={FormValidators.required}
              />
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4">
              <Field
                id="shippingPostcode"
                component={CheckoutField}
                inputGroup={false}
                label="Postal Code*"
                placeholder="Postal Code"
                name="address.postalCode"
                type="text"
                validate={FormValidators.required}
              />
            </div>
          </div>
          {/*
                <div className='col-xs-12 col-sm-7 col-md-7 col-lg-7'>
                    <Field component={CheckoutField} inputGroup={false} label='Phone' placeholder='Phone number' name='address.phonePrimary.phoneNumber' type='tel' />
                </div>
                            */}
          {
            <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
              <Field
                component={PhoneNumberField}
                inputGroup={false}
                label="Phone*"
                placeholder="Enter your phone"
                name={'address.phonePrimary.phoneNumber'}
                validate={[
                  FormValidators.required,
                  FormValidators.validPhoneNumber,
                ]}
              ></Field>
            </div>
          }
          {!anonymous && (
            <div
              className="col-xs-12"
              styleName="ShippingInfoFormScss.DefaultAddressField"
            >
              <Field
                component={CheckoutField}
                inputGroup={false}
                label="Make this my default delivery address"
                name="address.isDefault"
                type="checkbox"
              />
            </div>
          )}
        </div>
      </div>

      {/*
      <div className="row">
        <div styleName="ShippingInfoFormScss.CheckoutStages__title ShippingInfoFormScss.BillingDetails_title">
          <h4 styleName="ShippingInfoFormScss.ShippingDetailsHeader">
            Billing informations
          </h4>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div
            className="col-xs-12"
            styleName="ShippingInfoFormScss.SameAsShipping_checkobox_dield"
          >
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="Same as the shipping address"
              name="sameAsShipping"
              type="checkbox"
              onChange={handleChange}
            />
          </div>
          {!anonymous && !isEmpty(billingAddresses) && (
            <div>
              <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                <Field
                  component={CheckoutField}
                  inputGroup={false}
                  label="Select a saved address"
                  name="billingAddress.id"
                  type="select"
                  onChange={(e) => onSelectBillingAddress(e.target.value)}
                >
                  <option value="">Select address</option>
                  {billingAddresses.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.addressLine1}
                    </option>
                  ))}
                </Field>
              </div>
              {/*{isEmpty(billingAddresses) && (
                            <div className='col-xs-12 col-sm-5 col-md-5 col-lg-5' styleName='GlobalScss.Text-below-header ShippingInfoFormScss.Saved_address_text'>
                                There are no saved addresses in your address book
                            </div>
                        )}
                        {!isEmpty(billingAddresses) && (
                            <div className='col-xs-12 col-sm-5 col-md-5 col-lg-5' styleName='GlobalScss.Text-below-header ShippingInfoFormScss.Saved_address_text'>
                                There are saved addresses in your address book
                            </div>
                        )} */
      /*}
            </div>
          )}
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="First Name*"
              placeholder="Enter your first name"
              name={
                sameAsShipping
                  ? 'address.firstName'
                  : 'billingAddress.firstName'
              }
              type="text"
              validate={FormValidators.required}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="Last Name*"
              placeholder="Enter your last name"
              name={
                sameAsShipping ? 'address.lastName' : 'billingAddress.lastName'
              }
              type="text"
              validate={FormValidators.required}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              id="BillingAddress1"
              component={CheckoutField}
              inputGroup={false}
              label="Address line 1*"
              placeholder="Enter your address 1 here"
              name={
                sameAsShipping
                  ? 'address.addressLine1'
                  : 'billingAddress.addressLine1'
              }
              type="text"
              validate={FormValidators.required}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={CheckoutField}
              inputGroup={false}
              label="Address line 2"
              placeholder="Enter your address 2 here"
              name={
                sameAsShipping
                  ? 'address.addressLine2'
                  : 'billingAddress.addressLine2'
              }
              type="text"
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              id="billingCountry"
              component={CheckoutField}
              inputGroup={false}
              label="Country*"
              placeholder="Country"
              name={
                sameAsShipping
                  ? 'address.isoCountryAlpha2.alpha2'
                  : 'billingAddress.isoCountryAlpha2.alpha2'
              }
              type="select"
              validate={FormValidators.required}
            >
              <option value="" placeholder="Country">
                Select your country
              </option>
              {countryOptions.map((option) => (
                <option key={option.abbreviation} value={option.abbreviation}>
                  {option.name}
                </option>
              ))}
            </Field>
          </div>
          <div styleName="ShippingInfoFormScss.City-state-postalCode-div">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <Field
                id="billingCity"
                component={CheckoutField}
                inputGroup={false}
                label="City*"
                placeholder="City"
                name={sameAsShipping ? 'address.city' : 'billingAddress.city'}
                type="text"
                validate={FormValidators.required}
              />
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4">
              <Field
                id="billingPostcode"
                component={CheckoutField}
                inputGroup={false}
                label="Postal Code*"
                placeholder="Postal Code"
                name={
                  sameAsShipping
                    ? 'address.postalCode'
                    : 'billingAddress.postalCode'
                }
                type="text"
                validate={FormValidators.required}
              />
            </div>
          </div>
          {/*
                <div className='col-xs-12 col-sm-7 col-md-7 col-lg-7'>
                    <Field component={CheckoutField} inputGroup={false} label='Phone' placeholder='Phone number' name={sameAsShipping ? 'address.phonePrimary.phoneNumber' : 'billingAddress.phonePrimary.phoneNumber'} type='tel' />
                </div>
                            */
      /*}

          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <Field
              component={PhoneNumberField}
              inputGroup={false}
              label="Phone*"
              placeholder="Enter your phone"
              name={
                sameAsShipping
                  ? 'address.phonePrimary.phoneNumber'
                  : 'billingAddress.phonePrimary.phoneNumber'
              }
              type=""
              validate={[
                FormValidators.required,
                FormValidators.validPhoneNumber,
              ]}
            />
          </div>

          {!anonymous && (
            <div
              className="col-xs-12"
              styleName="ShippingInfoFormScss.DefaultAddressField"
            >
              <Field
                component={CheckoutField}
                inputGroup={false}
                label="Make this my default billing address"
                name="billingAddress.isDefault"
                type="checkbox"
              />
            </div>
          )}
        </div>
      </div>
*/}
      <div styleName="ShippingInfoFormScss.Continue_to_payment_button">
        <div className="col-xs-12">
          <Button type="submit" primary>
            Continue
          </Button>
        </div>
      </div>
      <div styleName="GlobalScss.Terms_and_conditions">
        By clicking on 'continue', you agree to the Reddot Food
        <p styleName="GlobalScss.Underline_brown_text">Terms and Conditions.</p>
      </div>
    </Form>
  )
);

ShippingInfoForm.Form = reduxForm({
  enableReinitialize: true,
  form: 'ShippingInfoForm',
})(ShippingInfoForm.Form);

const mapStateToProps = (state, props) => {
  return {
    anonymous: isAnonymous(state),
    isFetching:
      state.customerAddresses.isFetching || state.fulfillment.isFetching,
    customerAddresses: state.customerAddresses.customerAddresses || [],
    customerPayments: state.customerPayments.customerPayments || [],
    fulfillmentEstimations: getFulfillmentEstimations(state, props),
    initialValues: getInitialShippingValues(state),
  };
};

const dispatchResolve = (resolver, props) => {
  resolver.resolve(props.fetchCustomerAddresses);
  resolver.resolve(props.fetchFulfillmentEstimations);
  resolver.resolve(props.fetchCustomerPayments);
};

export default connect(mapStateToProps, {
  fetchCustomerAddresses,
  fetchCustomerPayments,
  fetchFulfillmentEstimations,
  saveFulfillmentGroup,
  saveBillingAddressForNewPayment,
  saveEmailForCart,
  change,
})(resolve(dispatchResolve)(ShippingInfoForm));

// ======= READ ONLY ========

export const ReadOnlyShippingInfoForm = ({ address, fulfillmentOption }) => (
  <div className="row">
    <div className="col-sm-6">
      <div styleName="ShippingInfoFormScss.AdressItem_value">
        {address.firstName}&nbsp;{address.lastName}
        <br />
        {address.addressLine1}
        <br /> {address.addressLine2}
        {address.addressLine2 && <br />}
        {address.city}
        <br />
        {address.isoCountryAlpha2 != null && address.isoCountryAlpha2.name}
        {address.isoCountryAlpha2 != null && <br />}
        {address.postalCode}
        <br />
        {address.phonePrimary && address.phonePrimary.phoneNumber}
      </div>
    </div>
  </div>
);
