import React, { useState } from 'react';
import KindOfFoodStyle from './KindOfFood.module.scss';
import Button from 'material-kit/components/Button';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import GlobalScss from '../../../../layout/style/common.scss';

const KindOfFood = ({ kindOfFoodRef }) => {
  const [selectedCategory, setSelectedCategory] = useState('sweets');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  return (
    <div id="kindOfFood" ref={kindOfFoodRef}>
      <div
        className={classNames(
          KindOfFoodStyle.ContainerDiv,
          'col-xs-12 col-md-12 col-lg-12'
        )}
      >
        <div className={KindOfFoodStyle.ContainerSubDiv}>
          <div className={KindOfFoodStyle.ContainerBorders}>
            <div
              className={classNames(
                KindOfFoodStyle.KindOfFoodHeaderDiv,
                'col-xs-12 col-md-12 col-lg-12'
              )}
            >
              <span className={KindOfFoodStyle.KindOfFoodHeaderText}>What</span>
              <span className={KindOfFoodStyle.KindHeaderText}>KIND</span>
              <span className={KindOfFoodStyle.KindOfFoodHeaderText}>
                Of Food
              </span>
            </div>

            <div
              className={classNames(
                KindOfFoodStyle.KindOfFoodMenuItemsParent,
                'col-xs-12 col-md-12 col-lg-12'
              )}
            >
              <span
                className={classNames({
                  [KindOfFoodStyle.KindOfFoodMenuTextActive]:
                    selectedCategory === 'spices',
                  [KindOfFoodStyle.KindOfFoodMenuText]:
                    selectedCategory !== 'spices',
                })}
                onClick={() => handleCategoryClick('spices')}
              >
                Whole Spices & Masalas
              </span>
              <span
                className={classNames({
                  [KindOfFoodStyle.KindOfFoodMenuTextActive]:
                    selectedCategory === 'sweets',
                  [KindOfFoodStyle.KindOfFoodMenuText]:
                    selectedCategory !== 'sweets',
                })}
                onClick={() => handleCategoryClick('sweets')}
              >
                Sweets
              </span>
              <span
                className={classNames({
                  [KindOfFoodStyle.KindOfFoodMenuTextActive]:
                    selectedCategory === 'savoury',
                  [KindOfFoodStyle.KindOfFoodMenuText]:
                    selectedCategory !== 'savoury',
                })}
                onClick={() => handleCategoryClick('savoury')}
              >
                Savoury
              </span>
              <span
                className={classNames({
                  [KindOfFoodStyle.KindOfFoodMenuTextActive]:
                    selectedCategory === 'pickles',
                  [KindOfFoodStyle.KindOfFoodMenuText]:
                    selectedCategory !== 'pickles',
                })}
                onClick={() => handleCategoryClick('pickles')}
              >
                Pickles
              </span>
              <span
                className={classNames({
                  [KindOfFoodStyle.KindOfFoodMenuTextActive]:
                    selectedCategory === 'sprinkles',
                  [KindOfFoodStyle.KindOfFoodMenuText]:
                    selectedCategory !== 'sprinkles',
                })}
                onClick={() => handleCategoryClick('sprinkles')}
              >
                Spicy Sprinkles
              </span>
              <span
                className={classNames({
                  [KindOfFoodStyle.KindOfFoodMenuTextActive]:
                    selectedCategory === 'groceries',
                  [KindOfFoodStyle.KindOfFoodMenuText]:
                    selectedCategory !== 'groceries',
                })}
                onClick={() => handleCategoryClick('groceries')}
              >
                Instant Mixes
              </span>
            </div>

            <div
              className={classNames(
                GlobalScss.GridWrapper,
                GlobalScss.GridWrapper__noGutter,
                'd-flex flex-column flex-md-row'
              )}
            >
              {selectedCategory === 'spices' && (
                <>
                  <div className={KindOfFoodStyle.KindOfImagesDivLargeScreen}>
                    <img
                      src="static/img/home/KIND_OF/Wholespices_and_Masalas.webp"
                      srcSet="
                        static/img/home/KIND_OF/Wholespices_and_Masalas.webp 480w,
                        static/img/home/KIND_OF/Wholespices_and_Masalas@2x.webp 800w,
                        static/img/home/KIND_OF/Wholespices_and_Masalas@3x.webp 1000w,
                      "
                      sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                      alt="Left Img"
                      className={KindOfFoodStyle.HomeRightImg}
                    />
                  </div>
                  <div className={KindOfFoodStyle.SubscribeImagesDiv}>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryTitle
                      )}
                    >
                      <div className={KindOfFoodStyle.tagSquare}>
                        <span>
                          <small>COMING SOON</small>
                        </span>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryTitle
                      )}
                    >
                      Whole Spices & Masalas
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryDesc
                      )}
                    >
                      Our range of whole and powdered spices is carefully
                      sourced and hygienically cleaned to preserve their natural
                      essence and purity
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CustomList
                      )}
                    >
                      <ul>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Aromatic
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Finest Quality
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Unadulterated
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Single Origin Spices
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.BuyButtonContainer
                      )}
                    >
                      <Button
                        disabled={true}
                        onClick={(e) => {
                          if (true) {
                            e.preventDefault(); // Because this Button has Link component this use in order to ignore click
                          }
                        }}
                        className={KindOfFoodStyle.ListButtonDisabled}
                        component={Link}
                        componentProps={{ to: '/spices' }}
                        primary
                        lg
                      >
                        Buy Spices & Masalas
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {selectedCategory === 'savoury' && (
                <>
                  <div className={KindOfFoodStyle.KindOfImagesDivLargeScreen}>
                    <img
                      src="static/img/home/KIND_OF/Savories.webp"
                      srcSet="
                        static/img/home/KIND_OF/Savories.webp 480w,
                        static/img/home/KIND_OF/Savories@2x.webp 800w,
                        static/img/home/KIND_OF/Savories@3x.webp 1000w
                      "
                      sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                      alt="Left Img"
                      className={KindOfFoodStyle.HomeRightImg}
                    />
                  </div>
                  <div className={KindOfFoodStyle.SubscribeImagesDiv}>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryTitle
                      )}
                    >
                      Savoury
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryDesc
                      )}
                    >
                      Our savory range offers crunchy, fried snacks made from
                      healthy chickpea flour or rice flour, combined with
                      aromatic spices
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CustomList
                      )}
                    >
                      <ul>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Protein rich
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Fresh Oil Used for every batch
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No Added Preservatives or Artifical Flavours
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.BuyButtonContainer
                      )}
                    >
                      <Button
                        className={KindOfFoodStyle.ListButton}
                        component={Link}
                        componentProps={{ to: '/savoury' }}
                        primary
                        lg
                      >
                        Buy Savoury
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {selectedCategory === 'sweets' && (
                <>
                  <div className={KindOfFoodStyle.KindOfImagesDivLargeScreen}>
                    <img
                      src="static/img/home/KIND_OF/Sweets.webp"
                      srcSet="
                        static/img/home/KIND_OF/Sweets.webp 480w,
                        static/img/home/KIND_OF/Sweets@2x.webp 800w,
                        static/img/home/KIND_OF/Sweets@3x.webp 1000w
                      "
                      sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                      alt="Left Img"
                      className={KindOfFoodStyle.HomeRightImg}
                    />
                  </div>
                  <div className={KindOfFoodStyle.SubscribeImagesDiv}>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryTitle
                      )}
                    >
                      Sweets
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryDesc
                      )}
                    >
                      Our artisan sweets feature either lentils, seeds, flour,
                      or nuts as the core ingredient, sweetened with unrefined
                      cane sugar (jaggery) or dates
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CustomList
                      )}
                    >
                      <ul>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Nutrient dense
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Protiein rich
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No Artificial sweetners
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No added preservatives or artificial flavours
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.BuyButtonContainer
                      )}
                    >
                      <Button
                        className={KindOfFoodStyle.ListButton}
                        component={Link}
                        componentProps={{ to: '/sweets' }}
                        primary
                        lg
                      >
                        Buy Sweets
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {selectedCategory === 'pickles' && (
                <>
                  <div className={KindOfFoodStyle.KindOfImagesDivLargeScreen}>
                    <img
                      src="static/img/home/KIND_OF/Pickles.webp"
                      srcSet="
                        static/img/home/KIND_OF/Pickles.webp 480w,
                        static/img/home/KIND_OF/Pickles@2x.webp 800w,
                        static/img/home/KIND_OF/Pickles@3x.webp 1000w
                      "
                      sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                      alt="Left Img"
                      className={KindOfFoodStyle.HomeRightImg}
                    />
                  </div>
                  <div className={KindOfFoodStyle.SubscribeImagesDiv}>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryTitle
                      )}
                    >
                      Pickles
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryDesc
                      )}
                    >
                      Fermented in oil, salt, and spices, our range features
                      popularly pickled Indian veggies and fruits, serving as
                      appetizers that support immunity & digestion if consumed
                      in moderation
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CustomList
                      )}
                    >
                      <ul>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No Vinegar (No sharp acidic taste){' '}
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No Palm Oil
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Low in calories
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No added preservatives or artificial flavours
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.BuyButtonContainer
                      )}
                    >
                      <Button
                        className={KindOfFoodStyle.ListButton}
                        component={Link}
                        componentProps={{ to: '/pickles' }}
                        primary
                        lg
                      >
                        Buy Pickles
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {selectedCategory === 'sprinkles' && (
                <>
                  <div className={KindOfFoodStyle.KindOfImagesDivLargeScreen}>
                    <img
                      src="static/img/home/KIND_OF/Spicy_Sprinkles.webp"
                      srcSet="
                        static/img/home/KIND_OF/Spicy_Sprinkles.webp 480w,
                        static/img/home/KIND_OF/Spicy_Sprinkles@2x.webp 800w,
                        static/img/home/KIND_OF/Spicy_Sprinkles@3x.webp 1000w,
                      "
                      sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                      alt="Left Img"
                      className={KindOfFoodStyle.HomeRightImg}
                    />
                  </div>
                  <div className={KindOfFoodStyle.SubscribeImagesDiv}>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryTitle
                      )}
                    >
                      Spicy Sprinkles
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CategoryDesc
                      )}
                    >
                      Our Podis/Sprinkles range blends superfood seeds, leaves,
                      and spices. These versatile condiments are ideal as
                      toppings on rice, salads, and also add depth to curries
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.CustomList
                      )}
                    >
                      <ul>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Nutrient Dense
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No Extracts, Only Real Ingredients
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No added preservatives or artificial flavours
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={classNames(
                        'col-xs-12 col-md-12 col-lg-12',
                        KindOfFoodStyle.BuyButtonContainer
                      )}
                    >
                      <Button
                        className={KindOfFoodStyle.ListButton}
                        component={Link}
                        componentProps={{ to: '/spicy-sprinkles-podis' }}
                        primary
                        lg
                      >
                        Buy Sprinkles
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {selectedCategory === 'groceries' && (
                <>
                  <div className={KindOfFoodStyle.KindOfImagesDivLargeScreen}>
                    <img
                      src="static/img/home/KIND_OF/Instant_Mixes.webp"
                      srcSet="
                        static/img/home/KIND_OF/Instant_Mixes.webp 480w,
                        static/img/home/KIND_OF/Instant_Mixes@2x.webp 800w,
                        static/img/home/KIND_OF/Instant_Mixes@3x.webp 1000w
                      "
                      sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                      alt="Left Img"
                      className={KindOfFoodStyle.HomeRightImg}
                    />
                  </div>
                  <div className={KindOfFoodStyle.SubscribeImagesDiv}>
                    <div
                      className={classNames(
                        KindOfFoodStyle.CategoryTitle,
                        'col-xs-12 col-md-12 col-lg-12'
                      )}
                    >
                      Instant Mixes
                    </div>
                    <div
                      className={classNames(
                        KindOfFoodStyle.CategoryDesc,
                        'col-xs-12 col-md-12 col-lg-12'
                      )}
                    >
                      Our Instant Mixes bring traditional recipes to your table
                      in no time. Perfect for busy professionals, students, and
                      home cooks seeking convenience.
                    </div>
                    <div
                      className={classNames(
                        KindOfFoodStyle.CustomList,
                        'col-xs-12 col-md-12 col-lg-12'
                      )}
                    >
                      <ul>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Ready to Mix
                          </span>
                        </li>

                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            Only Real Ingredients
                          </span>
                        </li>
                        <li>
                          <span className={KindOfFoodStyle.CustomListText}>
                            No added preservatives
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={classNames(
                        KindOfFoodStyle.BuyButtonContainer,
                        'col-xs-12 col-md-12 col-lg-12'
                      )}
                    >
                      <Button
                        className={KindOfFoodStyle.ListButton}
                        component={Link}
                        componentProps={{ to: '/instant-mixes' }}
                        primary
                        lg
                      >
                        Buy Instant Mix
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          KindOfFoodStyle.KindOfFoodParentDiv,
          'col-xs-12 col-md-12 col-lg-12'
        )}
      >
        <div className={KindOfFoodStyle.KindOfFoodImgParentDiv}>
          <img
            src="/static/img/home/KIND_OF/kindof_bottom_wave.svg"
            className={KindOfFoodStyle.KindOfFoodImg}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default KindOfFood;
