import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './UserOptionsModal.scss';
import UserOptionsModalCss from './UserOptionsModal.scss';

const UserOptionsModal = ({ isOpen, onRequestClose, onLogout }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onRequestClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Modal"
      parentSelector={() => document.getElementById('AppContent')}
      className={UserOptionsModalCss['user_options_modal_content']}
      overlayClassName={UserOptionsModalCss['user_options_modal_overlay']}
    >
      <div
        ref={modalRef}
        className="row"
        styleName="UserOptionsModalCss.UserOptionsModal_Content"
      >
        <div
          className="col-xs-12"
          styleName="UserOptionsModalCss.UserOptionsModal_Title"
        >
          <a className="pull-right" onClick={onRequestClose}>
            <img
              styleName="UserOptionsModalCss.CloseIcon"
              src="/static/img/close.svg"
            />
          </a>
        </div>
        <div className="col-xs-12" styleName="UserOptionsModalCss.LogoutButton">
          <li>
            <a href="javascript:void(0);" onClick={onLogout}>
              Logout
            </a>
          </li>
        </div>
        <div className="col-xs-12">
          <li>
            <NavLink to="/account">My Account</NavLink>
          </li>
        </div>
      </div>
    </Modal>
  );
};

export default UserOptionsModal;
