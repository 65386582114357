/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { withScroll } from 'core/components/ScrollToTop';
import {
  addPromo,
  fetchCart,
  removePromo,
  removeFromCart,
  toggleMiniCart,
  updateQuantity,
  addToCart,
  updateSku,
} from 'cart/actions';
import { fetchProductBatch } from 'catalog/product/actions';
import { isAnonymous } from 'auth/selectors';
import { getCart } from 'cart/selectors';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CartContext } from 'cart/constants';
import Button from 'material-kit/components/Button';
import CartOrderItem from 'cart/components/CartOrderItem';
import CartPricingSummary from 'cart/components/CartPricingSummary';
import PromoCodes from 'cart/components/PromoCodes';
import isEmpty from 'lodash/isEmpty';
import CartScss from './Cart.scss';
import GlobalScss from '../../../layout/style/common.scss';
import {
  trackCartExit,
  trackCartToShop,
  trackTimeOnCartPage,
} from '../../../analytics/events';

const Cart = ({
  addToCart,
  addedOfferCodes = [],
  addPromo,
  anonymous,
  fulfillmentGroup,
  itemAdjustmentsValue,
  itemCount,
  orderItem,
  removePromo,
  removeFromCart,
  subTotal,
  totalAdjustmentsValue,
  totalTax,
  totalShipping,
  total,
  updateQuantity,
  updateSku,
}) => {
  useEffect(() => {
    const startTime = Date.now();
    return () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // Time in seconds
      trackTimeOnCartPage(timeSpent);
      if (location.pathname !== '/checkout/shipping') {
        trackCartExit();
      }
    };
  }, []);

  const hasOrderItems = !isEmpty(orderItem);
  // this tries to resolve issue described in the RE-207
  if (typeof document === 'undefined') {
    return null;
  }
  const remainingAmount = Math.max(60 - subTotal.amount, 0);
  return (
    <div styleName="CartScss.Cart">
      <Helmet titleTemplate="%s | Reddot Food Store">
        <title>Cart</title>
        <meta name="description" content="Cart" />
        <link rel="canonical" href="/cart" />
      </Helmet>

      <div className="container">
        <div id="cart" className="row">
          {hasOrderItems && (
            <div className="col-lg-12">
              <h5>The following products are in your cart</h5>
            </div>
          )}

          {/* <!-- Shopping Cart Items --> */}

          <div
            className={hasOrderItems ? 'col-lg-8' : 'col-lg-12'}
            styleName={
              hasOrderItems
                ? ''
                : 'CartScss.Cart__itemSummary_without_ordet_item_margin'
            }
          >
            <div className="card" styleName="CartScss.Cart__itemSummary">
              <div styleName="CartScss.Cart__itemSummary__title">
                <h4>Shopping Cart</h4>
                {!hasOrderItems ? (
                  <span
                    className="pull-right small-text"
                    styleName="GlobalScss.ResultWrapperHeader__info_text CartScss.ResultWrapperHeader__info_text_position"
                  >
                    You need to shop for €{remainingAmount.toFixed(2)} more to
                    avail free shipping.
                  </span>
                ) : (
                  <Button
                    onClick={(e) => trackCartToShop()}
                    styleName="GlobalScss.Large_button CartScss.ContinueShoppingButtonTextPosition CartScss.ContinueShoppingButtonHeaderPosition"
                    component={Link}
                    componentProps={{
                      to: '/' + localStorage.getItem('lastViewed') || '/',
                    }}
                    lg
                    simple
                  >
                    Continue shopping
                    <img src="/static/img/secondary_CTA_icon.svg" />
                  </Button>
                )}
              </div>

              {hasOrderItems && (
                <div styleName="CartScss.ResultWrapperHeader__info_text_position_div">
                  <span styleName="GlobalScss.ResultWrapperHeader__info_text CartScss.ResultWrapperHeader__info_text_position">
                    You need to shop for €{remainingAmount.toFixed(2)} more to
                    avail free shipping.
                  </span>
                </div>
              )}
              {hasOrderItems ? (
                <div>
                  {orderItem
                    .filter((o) => !o.parentOrderItemId)
                    .map((orderItem) => (
                      <CartOrderItem
                        key={orderItem.id}
                        removeFromCart={removeFromCart}
                        updateQuantity={updateQuantity}
                        addToCart={addToCart}
                        updateSku={updateSku}
                        {...orderItem}
                      />
                    ))}
                </div>
              ) : (
                <div styleName="CartScss.Cart__emptyMessage">
                  <p styleName="CartScss.Cart__emptyMessage_text">
                    Your cart is empty
                  </p>
                  <div>
                    <Button
                      styleName="GlobalScss.Large_button CartScss.ShopAllButtonWidth"
                      component={Link}
                      componentProps={{ to: '/' }}
                      primary
                      lg
                    >
                      Shop all
                    </Button>
                    <Button
                      onClick={(e) => trackCartToShop()}
                      styleName="GlobalScss.Large_button CartScss.ContinueShoppingButtonTextPosition"
                      component={Link}
                      componentProps={{
                        to: '/' + localStorage.getItem('lastViewed') || '/',
                      }}
                      lg
                      simple
                    >
                      Continue shopping
                      <img src="/static/img/secondary_CTA_icon.svg" />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <!-- Cart Summary --> */}
          {hasOrderItems && (
            <div className="col-lg-4">
              <CartPricingSummary
                anonymous={anonymous}
                context={CartContext.Cart}
                fulfillmentGroup={fulfillmentGroup}
                itemAdjustmentsValue={itemAdjustmentsValue}
                itemCount={itemCount}
                orderItem={orderItem}
                subTotal={subTotal}
                totalAdjustmentsValue={totalAdjustmentsValue}
                totalTax={totalTax}
                totalShipping={totalShipping}
                total={total}
                addedOfferCodes={addedOfferCodes}
                addPromo={addPromo}
                removePromo={removePromo}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

class CartContainer extends PureComponent {
  componentDidMount() {
    this.props.toggleMiniCart(false);
  }

  componentWillReceiveProps(nextProps) {
    const { anonymous: wasAnonymous } = this.props;
    const { anonymous } = nextProps;
    if (wasAnonymous !== anonymous && !nextProps.isFetching) {
      nextProps.fetchCart();
    }

    fetchMissingProductsIfNeeded(nextProps);
  }

  /* bilo ovako
    render() {
        return <Cart {...this.props} />
    }
    */

  render() {
    return <Cart {...this.props} orderItem={this.props.orderItems} />;
  }
}

const fetchMissingProductsIfNeeded = (props) => {
  const { orderItem } = props; //sefer orderItem -> orderItems
  if (orderItem) {
    const missingProducts = orderItem
      .filter((o) => !o.parentOrderItemId && !o.product)
      .map((o) => o.productId);
    if (!isEmpty(missingProducts)) {
      props.fetchProductBatch(missingProducts);
    }
  }
};

const mapStateToProps = (state, props) => {
  return {
    ...getCart(state, props),
    anonymous: isAnonymous(state, props),
  };
};

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchCart);
  }

  resolver.resolve(fetchMissingProductsIfNeeded, props);
};

export default connect(mapStateToProps, {
  addPromo,
  fetchCart,
  fetchProductBatch,
  removeFromCart,
  removePromo,
  toggleMiniCart,
  updateQuantity,
  addToCart,
  updateSku,
})(resolve(dispatchResolve)(withScroll(CartContainer)));
